<template>
<div class="main">
  <router-view name="UserListPage" v-slot="{ Component }">
    <transition name="up" mode="out-in">

        <component :is="Component" />

    </transition>
  </router-view>
</div>
</template>

<script>

export default {
  name: 'UserListPage',
}

</script>

<style scoped>

</style>
